import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Users } from 'src/app/models/Users';
import { HttpService } from '../http/http.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private currentUserSource = new ReplaySubject<Users>(1);
  currentUser$ = this.currentUserSource.asObservable();
  // helper = new JwtHelperService();

  constructor(private httpServer: HttpService
    , private router: Router
    , private toastr: ToastrService) { }

  // Register(email: string, password: string, name: string) {

  // }

  Register(model: any) {
    return this.httpServer.httpPost("Account/Register", model)

  }

  login(model: any) {

    return this.httpServer.httpPost("Account/login", model).pipe(
      map((res: Users) => {
        const user = res;
        // console.log(user);
        // console.log(user.roles);
        // this.router.navigateByUrl('/dashboard/home')
        if (user) {
          this.setCurrentUser(user);
          this.router.navigateByUrl('/dashboard/home')
          // this.Open();
        }

      })
    )
  }

  // Open() {
  //   const Nameroles: string[] = JSON.parse(localStorage.getItem("user")).roles;
  //   // console.log(Nameroles);

  //   if (Nameroles.includes("Admin")) {
  //     // console.log("-------");
  //     this.router.navigateByUrl('/dashboard/home')
  //   }
  //   else if (Nameroles.includes("Casher")) {
  //     this.router.navigateByUrl('/dashboard/invoice')
  //   }
  //   else {
  //     this.router.navigateByUrl('/sigin')

  //   }
  // }

  setCurrentUser(user: Users) {
    // user.roles = [];
    // const roles = this.getDecodedToken(user.token).role; //casher , admin
    // Array.isArray(roles) ? user.roles = roles : user.roles.push(roles);
    localStorage.setItem('user', JSON.stringify(user));
    // console.log(user.token);
    this.currentUserSource.next(user);
  }

  logout() {
    localStorage.removeItem('user');
    this.currentUserSource.next(null);
    this.router.navigateByUrl('/signin')
    console.log("logout");

  }

  // getCurrentUser() {
  //   this.Open();
  //   // const user: Users = JSON.parse(localStorage.getItem('user'));

  //   // this.currentUserSource.next(user);
  //   // const userroles: any = user.roles;
  //   // if (userroles === "Admin") {
  //   //   this.router.navigateByUrl('/dashboard/home');
  //   // }
  //   // else if (userroles === "Casher") {
  //   //   this.router.navigateByUrl('/dashboard/invoice');
  //   // }
  //   // else {
  //   //   this.router.navigateByUrl('/sigin');

  //   // }

  // }

  getDecodedToken(token) {
    // return JSON.parse(window.atob(token.split(('.'), [1])))

    // const encoded = user.token;
    // const decoded= JSON.parse(window.atob(encoded))
    // console.log(encoded);
    // console.log(decoded);
    // return this.helper.decodeToken(token);
  }
  loginAuth() {
    const users: Users = JSON.parse(localStorage.getItem('user'));
    // return !this.helper.getTokenExpirationDate(users.token);
  }
}

