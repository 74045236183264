import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Users } from '../models/Users';
import { AccountService } from '../services/account/account.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

    signinformRow: Users;
    signinform: FormGroup;

    constructor(private accountService: AccountService,
      private fb: FormBuilder,
      private toastr: ToastrService ) {}

    ngOnInit() {
      this.signinform = this.fb.group({
        userName: ['', [Validators.required, Validators.maxLength(15), Validators.minLength(2)]],
        password: ['', [Validators.required, Validators.pattern(/(?=.*\d)(?=.*[a-z]).{8,}/)]]
      });
    }


    signin() {
      this.accountService.login(this.signinform.value).subscribe(() => {
        this.toastr.success('تم تسجيل الدخول بنجاح')
      }, error => {
        this.toastr.error(error.error)
      })
    }
}
