<div>
    <apx-chart
        [series]="chartOptions?.series"
        [plotOptions]="chartOptions?.plotOptions"
        [dataLabels]="chartOptions?.dataLabels"
        [chart]="chartOptions?.chart"
        [fill]="chartOptions?.fill"
        [yaxis]="chartOptions?.yaxis"
        [xaxis]="chartOptions?.xaxis"
        [title]="chartOptions?.title"
        [series]="chartOptions?.donutSeries"
        [labels]="chartOptions.labels"
        [responsive]="chartOptions.responsive"
        [colors]="chartOptions.colors"
        [tooltip]="chartOptions.tooltip"
    
    >
    </apx-chart>
</div>
