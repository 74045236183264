import { Component, Input, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
NgControl
@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() type = 'text';

  constructor(@Self() public ngControl: NgControl) { 
    this.ngControl.valueAccessor = this;
  }

  writeValue(obj: any): void {
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }


  // $(document).ready(function(): void{
  
  //   $('.text-field-input').on('focus', function(){
  //     $(this).closest('.field-wrapper').addClass('focused');
  //   });
    
  //   $('.text-field-input').on('blur', function(){
    
  //     if  ( $(this).val() === '') {
  //         $(this).closest('.field-wrapper').removeClass('focused');
  //     }
  //   });
    
  // })
  
  
}
