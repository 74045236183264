import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getPaginatedResult, getPaginationHeaders } from '../paginationHelper';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  apiUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }


  private handleError(error: any) {
    return observableThrowError(error.error || 'Server error');
  }

  httpGetBy(url, pageNumber: number, pageSize: number) {
    let params = getPaginationHeaders(pageNumber, pageSize);

    return getPaginatedResult<Partial<any>>(this.apiUrl + url, params, this.http);
  }

  httpGetByrepot(url, pageNumber: number, pageSize: number, minDate?: string, maxDate?: string) {
    let params = getPaginationHeaders(pageNumber, pageSize);
    params = params.append("minDate", minDate);
    params = params.append("maxDate", maxDate);
    // console.log(params);

    return getPaginatedResult<Partial<any>>(this.apiUrl + url, params, this.http);
  }

  httpGetClientsFiltered(url, pageNumber: number, pageSize: number, statusId?: number, 
          countryId?: number, productId?: number, projectTypeId?:number, term?: string) {
      
      let params = getPaginationHeaders(pageNumber, pageSize);
      params = params.append("statusId", statusId)
      params = params.append("countryId", countryId)
      params = params.append("productId", productId)
      params = params.append("projectTypeId", projectTypeId)
      params = params.append("term", term)

      return getPaginatedResult<Partial<any>>(this.apiUrl + url, params, this.http);

  }



  httpGet<T>(url: string) {
    return this.http.get<T>(this.apiUrl + url).pipe(
      tap((res: any) => {
        res

        // console.log(url);

        // console.log(res);
      }
      ),
      catchError(this.handleError)
    );
  }

  httpPost<T>(url: string, model: any = null) {
    return this.http.post<T>(this.apiUrl + url, model).pipe(
      tap((res: any) => {
        res
        // console.log(url);
        // console.log(model);

        // console.log(res);
      }
      ),
      catchError(this.handleError)
    );
  }

  httpPut<T>(url: string, model: any = null) {
    return this.http.put<T>(this.apiUrl + url, model).pipe(
      tap((res: any) => {
        res
        // console.log(url);
        // console.log(model);

        // console.log(res);
      }
      ),
      catchError(this.handleError)
    );
  }

  httpDelete(url: string) {
    return this.http.delete(this.apiUrl + url).pipe(
      tap((res: any) => {
        res

        // console.log(url);

        // console.log(res);
      }
      ),
      catchError(this.handleError)
    );
  }
}
