<div class="form-group">
    <div class="wrapper">
        <label class="text-muted">{{ label }}</label>
        <input
            [class.is-invalid]="ngControl.touched && ngControl.invalid"
            type="{{ type }}"
            class="form-control"
            [formControl]="ngControl.control"
            />
            <!-- placeholder="{{ label }}" -->
        <div *ngIf="ngControl.control.errors?.required" class="invalid-feedback">من فضلك ادخل {{ label }}</div>
        <div *ngIf="ngControl.control.errors?.minlength" class="invalid-feedback">
            {{ label }} يجب الا يقل عن {{ ngControl.control.errors.minlength['requiredLength'] }}
        </div>
        <div *ngIf="ngControl.control.errors?.maxlength" class="invalid-feedback">
            {{ label }} يجب الا يكون اكثر من{{ ngControl.control.errors.maxlength['requiredLength'] }}
        </div>
        <div *ngIf="ngControl.control.errors?.isMatching" class="invalid-feedback">Passwords do not match</div>
    </div>
</div>
