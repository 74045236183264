export class NextConfig {
    public static config = {
        layout: 'vertical', // vertical, horizontal
        subLayout: '', // horizontal-2
        collapseMenu: false,
        layoutType: 'menu-light', // menu-dark, menu-light, dark
        headerBackColor: 'background-blue', // background-blue, background-red, background-purple, background-info, background-dark
        rtlLayout: true,
        navFixedLayout: false,
        headerFixedLayout: false,
        boxLayout: false
    };
}
