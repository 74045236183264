import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import ApexCharts from 'apexcharts/dist/apexcharts.common.js';
import { ApexChartService } from './apex-chart.service';

@Component({
  selector: 'app-apex-chart',
  templateUrl: './apex-chart.component.html',
  styleUrls: ['./apex-chart.component.scss']
})
export class ApexChartComponent implements OnInit, OnChanges {
  @Input() chartID: string;
  @Input() chartConfig: any;
  @Input() xAxis: string[];
  @Input() newData: number[];

  public chart: any;

  constructor(private apexEvent: ApexChartService) { }


 

  ngOnInit() {

    this.renderChart();
    // this.subscribeToEvents();

  //   setTimeout(() => {
  //     this.chart = new ApexCharts(document.querySelector('#' + this.chartID), this.chartConfig);
  //     this.chart.render();
  // });

  // this.apexEvent.changeTimeRange.subscribe(() => {
  //     if (this.xAxis) {
  //         this.chart.updateOptions({
  //             xaxis: this.xAxis
  //         });
  //     }
  // });

  // this.apexEvent.changeSeriesData.subscribe(() => {
  //     if (this.newData) {
  //         this.chart.updateSeries([
  //             {
  //                 data: this.newData
  //             }
  //         ]);
  //     }
  // });

    // this.apexEvent.changeSeriesData.subscribe(() => {
    //   if (this.newData) {
    //     this.chart.updateSeries([
    //       {
    //         data: this.newData
    //       }
    //     ]);
    //   }
    // });
  }



  ngOnChanges(changes: SimpleChanges) {
    if (changes.newData && !changes.newData.firstChange) {
      this.updateChartOptions();
    }
  }


  private renderChart() {
    setTimeout(() => {
      this.chart = new ApexCharts(document.querySelector('#' + this.chartID), this.chartConfig);
      this.chart.render();
    });
  }

  // private subscribeToEvents() {
  //   this.apexEvent.changeTimeRange.subscribe(() => {
  //     if (this.xAxis) {
  //       this.updateChartOptions();
  //     }
  //   });

  //   this.apexEvent.changeSeriesData.subscribe(() => {
  //     if (this.newData) {
  //       // Perform any necessary operations when series data changes
  //     }
  //   });
  // }

  private updateChartOptions() {
      if(this.xAxis){

      this.chart.updateOptions({
        xaxis: {
          categories: this.xAxis
        }
      });

      }
      
      
      this.chart.updateSeries(
        [{
          data: this.newData
        }],
        // Animation options for a smooth transition
        500 // Transition duration in milliseconds
      );
  }


}
