<!-- <div class="auth-wrapper">
    <div class="auth-content">
        <div class="card">
            <div class="row align-items-center text-center">
                <div class="col-md-12">
                  <form class="row " [formGroup]="signinform" (ngSubmit)="signin()">
                    <div class="card-body">

                            <img src="assets/images/logo-dark.png" alt="" class="img-fluid mb-4" />
                            <h4 class="mb-3 f-w-400">Signin</h4>


                            <div class="form-group mb-3"> -->
<!-- <label for="userName" class=""> اسم المستخدم</label> -->
<!-- <input type="text" id="username" formControlName="userName" class="form-control" placeholder="Username" />
                            </div>


                            <div class="form-group mb-4">
                                <input type="password" id="password" formControlName="password" class="form-control" placeholder="Password" />
                            </div>


                            <div class="custom-control custom-checkbox text-left mb-4 mt-2">
                                <input type="checkbox" class="custom-control-input" id="customCheck1" />
                                <label class="custom-control-label" for="customCheck1">Save credentials.</label>
                            </div>


                            <button class="btn btn-block btn-primary mb-4" type="submit" 
                                >Signin</button>


                            <p class="mb-2 text-muted">Forgot password? <a [routerLink]="['/auth/reset-password']" class="f-w-400">Reset</a></p>
                            <p class="mb-0 text-muted">Don’t have an account? <a [routerLink]="['/auth/signup']" class="f-w-400">Signup</a></p>
                            
                          </div>
                        </form>
                    
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="auth-wrapper align-items-stretch bg-img">
    <div class="flex-grow-1">
        <div class="h-100 d-md-flex align-items-center auth-side-img">
            <div class="col-sm-10 auth-left w-auto">
                <img src="assets/images/bg1.png" alt="" class="wid-280 img-fill" />
            </div>
        </div>

        <div class="auth-side-form card">
            <div class="text-center auth-content">
                <form class="row" [formGroup]="signinform" (ngSubmit)="signin()">
                    <div class="text-center card-body">
                        <div class="m-b-45">
                            <img src="assets/images/Easacc-new-logo-768x530.png" alt="logo" class="wid-180 img-fluid" />
                        </div>
                        <!-- <h4 class="mb-3 text-center f-w-400">تسجيل الدخول</h4> -->

                        <div class="form-group mb-3">
                            <!-- <label for="userName" class=""> اسم المستخدم</label> -->
                            <input type="text" id="username" formControlName="userName" class="form-control" placeholder="اسم المستخدم" />
                        </div>
                        <!-- <app-text-input
                          [formControl]='signinform.controls["userName"]'
                          [label]='"اسم المستخدم"'
                          [type]='"text"'>
                      </app-text-input> -->

                        <div class="form-group mb-4">
                            <input
                                type="password"
                                id="password"
                                formControlName="password"
                                class="form-control"
                                placeholder="كلمة المرور"
                            />
                        </div>

                        <!-- <div class="custom-control custom-checkbox text-left mb-4 mt-2">
                            <input type="checkbox" class="custom-control-input" id="customCheck1" />
                            <label class="custom-control-label" for="customCheck1"> حفظ كلمة المرور </label>
                        </div> -->

                        <button class="btn btn-color rad-btn btn-block btn-primary mb-4" type="submit">تسجيل الدخول</button>

                        <p class="mb-2 text-muted">
                            هل نسيت كلمة المرور ؟ <a [routerLink]="['/auth/reset-password']" class="f-w-400">استعادة</a>
                        </p>
                        <p class="mb-0 text-muted">ليس لديك حساب بعد ؟ <a [routerLink]="['/auth/signup']" class="f-w-400">تسجيل جديد</a></p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
