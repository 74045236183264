import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ApexChartService } from '../theme/shared/components/chart/apex-chart/apex-chart.service';
import { ApexCharts } from 'apexcharts/dist/apexcharts.common.js';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexFill,
  ApexResponsive,
  ApexNonAxisChartSeries,
  ApexTooltip,
  ApexLegend,
  ApexMarkers
} from "ng-apexcharts";

export type ChartOptions = {
  series?: ApexAxisChartSeries;
  donutSeries?: ApexNonAxisChartSeries;
  chart?: ApexChart;
  colors?: any[];
  dataLabels?: ApexDataLabels;
  plotOptions?: ApexPlotOptions;
  yaxis?: ApexYAxis | ApexYAxis[];
  xaxis?: ApexXAxis;
  fill?: ApexFill;
  title?: ApexTitleSubtitle;
  responsive?: ApexResponsive[];
  labels?: any;
  tooltip?: ApexTooltip;
};

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnChanges {
  // @ViewChild('host', { static: true }) host: ElementRef<HTMLDivElement>;


  public chartOptions: Partial<ChartOptions>;


  // @Input() chartID: string;
  // @Input() chartConfig: any;
  @Input() xAxis: string[];
  @Input() mainTitle: string;
  @Input() data1: any;
  @Input() title1: string;
  @Input() data2: any;
  @Input() title2: string;
  @Input() type: string;

  public chart: any;

  constructor() {
  }


  ngOnChanges() {

    switch (this.type) {
      case 'donut': this.donutChartOption(this.data1, this.data2);
        break;

      case 'twoBar': this.TwoBarChartoption(this.mainTitle, this.xAxis
        , this.data1, this.title1, this.data2, this.title2);

        break;

      case 'mixed': this.MixedChartoption(this.mainTitle, this.xAxis
        , this.data1, this.title1, this.data2, this.title2);

        break;

      case 'mixedLine': this.MixedLineChartOption(this.mainTitle, this.xAxis
          , this.data1, this.title1, this.data2, this.title2);
  
        break;
        
      default: this.OneBarChartoption(this.mainTitle, this.xAxis
        , this.data1, this.title1);

        break;
    }

    // if (this.type === 'donut') {
    //   this.donutChartOption(this.data1, this.data2);
    // }
    // else if (this.type === 'twoBar') {
    //   this.TwoBarChartoption(this.mainTitle, this.xAxis
    //     , this.data1, this.title1, this.data2, this.title2);
    // }
    // else if (this.type === 'mixed') {
    //   this.MixedChartoption(this.mainTitle, this.xAxis
    //     , this.data1, this.title1, this.data2, this.title2);
    // }
    // else {
    //   this.OneBarChartoption(this.mainTitle, this.xAxis
    //     , this.data1, this.title1);
    // }

  }


  OneBarChartoption(mainTitle?: string, xAxis?: string[], data1?: number[], title1?: string) {
    this.chartOptions = {
      series: [
        {
          name: title1,
          data: data1,
          color: '#207284'
        }
      ],
      chart: {
        type: "bar",
        height: 350,
        fontFamily: 'Cairo sans-serif'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
        }
      },
      dataLabels: {
        enabled: false
      },
      // stroke: {
      //   show: true,
      //   width: 2,
      //   colors: ["transparent"]
      // },
      xaxis: {
        categories: xAxis
      },
      yaxis: {
        title: {
          text: mainTitle
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val + "";
          }
        }
      },
      fill: {
        opacity: 1
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return "$ " + val + " thousands";
      //     }
      //   }
      // }
    };

  }



  TwoBarChartoption(mainTitle?: string, xAxis?: string[], data1?: number[], title1?: string, data2?: number[], title2?: string) {
    this.chartOptions = {
      series: [
        {
          name: title1,
          data: data1,
          color: '#207284'
        },
        {
          name: title2,
          data: data2,
          color: '#94CF7A'
        },
      ],
      chart: {
        type: "bar",
        height: 350,
        fontFamily: 'Cairo sans-serif'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
        }
      },
      dataLabels: {
        enabled: false
      },
      // stroke: {
      //   show: true,
      //   width: 2,
      //   colors: ["transparent"]
      // },
      xaxis: {
        categories: xAxis
      },
      // yaxis: {
      //   title: {
      //     text: mainTitle
      //   },
      //   axisBorder: {
      //     show: false
      //   },
      //   axisTicks: {
      //     show: false
      //   },
      //   labels: {
      //     show: true,
      //     formatter: function (val) {
      //       return val + "";
      //     }
      //   }
      // },
      yaxis: [
        {
          // title: {
          //   text: "Website Blog"
          // },
          show: false
        },
        {
          opposite: true,
          // title: {
          //   text: "Socials s Media"
          // },
          show: false

        }
      ],
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            // Use toLocaleString to format the value with commas for thousands
            return val.toLocaleString();
          }
        }
      },
    };

  }



  MixedChartoption(mainTitle?: string, xAxis?: string[], data1?: number[], title1?: string, data2?: number[], title2?: string) {
    this.chartOptions = {
      series: [
        {
          name: title1,
          type: "column",
          data: data1,
          color: '#207284'
        },
        {
          name: title2,
          type: "line",
          data: data2,
          color: '#94CF7A'
        },
      ],
      chart: {
        height: 350,
        type: "line"
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
        }
      },
      dataLabels: {
        enabled: false,
        // enabledOnSeries: [1]
      },
      // stroke: {
      //   show: true,
      //   width: 2,
      //   colors: ["transparent"]
      // },
      xaxis: {
        // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        categories: xAxis
      },
      // yaxis: {
      //   title: {
      //     text: mainTitle
      //   },
      //   axisBorder: {
      //     show: false
      //   },
      //   axisTicks: {
      //     show: false
      //   },
      //   labels: {
      //     show: true,
      //     formatter: function (val) {
      //       return val + "";
      //     }
      //   }
      // },
      yaxis: [
        {
          // title: {
          //   text: "Website Blog"
          // },
          show: false
        },
        {
          opposite: true,
          // title: {
          //   text: "Socials s Media"
          // },
          show: false

        }
      ],
      fill: {
        opacity: 1
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return "$ " + val + " thousands";
      //     }
      //   }
      // }
    };

  }





  MixedLineChartOption(mainTitle?: string, xAxis?: string[], data1?: number[], title1?: string, data2?: number[], title2?: string) {
    this.chartOptions = {
      series: [
        {
          name: title1,
          data: data1,
          color: '#207284'
        },
        {
          name: title2,
          data: data2,
          color: '#94CF7A'
        },
      ],
      chart: {
        height: 350,
        type: "line"
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
        }
      },
      dataLabels: {
        enabled: false,
        // enabledOnSeries: [1]
      },
      // stroke: {
      //   show: true,
      //   width: 2,
      //   colors: ["transparent"]
      // },
      xaxis: {
        // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        categories: xAxis
      },
      // yaxis: {
      //   title: {
      //     text: mainTitle
      //   },
      //   axisBorder: {
      //     show: false
      //   },
      //   axisTicks: {
      //     show: false
      //   },
      //   labels: {
      //     show: true,
      //     formatter: function (val) {
      //       return val + "";
      //     }
      //   }
      // },
      yaxis: [
        {
          // title: {
          //   text: "Website Blog"
          // },
          show: false
        },
        {
          opposite: true,
          // title: {
          //   text: "Socials s Media"
          // },
          show: false

        }
      ],
      fill: {
        opacity: 1
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val: number) {
      //       // Use toLocaleString to format the value with commas for thousands
      //       return "$ " + val.toLocaleString() + " thousands";
      //     }
      //   }
      // },
    };

  }







  donutChartOption(data1?: number, data2?: number) {
    this.chartOptions = {
      donutSeries: [data1, data2],
      chart: {
        height: 235,
        type: "donut",
      },
      fill: {
        colors: ["#207284", "#94CF7A", "#1A4B58"],
      },
      colors: ['#207284', '#94CF7A', "#207284"],

      // dataLabels: {
      //   enabled: true,
      //   formatter: function (val) {
      //     return val + "%"
      //   },
      // },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {

              },
              value: {
                show: true,
                formatter: function (val) {
                  return val;
                },
              }
            }
          }
        }
      },
      labels: ["السعودية", "مصر"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }
}

