<div style="margin: 20px 0px" class="form-group">
    <div class="wrapper">
        <label class="text-muted">{{ label }}</label>

        <select *ngIf="type === 'default'"
            [class.is-invalid]="ngControl.touched && ngControl.invalid"
            class="form-control"
            [formControl]="ngControl.control"
            (change)="onChange($event.target.value)"
            >
            <!-- <option [ngValue]="null">المحافظة</option> -->
            <option *ngFor="let type of list" [value]="type.id">{{ type.name || type.name_ar }}</option>
        </select>


        <select *ngIf="type !== 'default'"
            [class.is-invalid]="ngControl.touched && ngControl.invalid"
            class="form-control"
            [formControl]="ngControl.control"
            (change)="onChange($event.target.value)"
            >
            <!-- <option [ngValue]="null">المحافظة</option> -->
            <option *ngFor="let type of list" [value]="type.id">{{ type.name_en }}</option>
        </select>

        <div *ngIf="ngControl.control.errors?.required" class="invalid-feedback">من فضلك ادخل {{ label }}</div>
    </div>
</div>
