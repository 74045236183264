import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PageNotFoundComponent } from './maintenance/page-not-found/page-not-found.component';
import { SigninComponent } from './signin/signin.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { AuthGuard } from "./services/guard/auth.guard";

const routes: Routes = [
  {
    path: 'dashboard',
    component: AdminComponent,
    children: [
      // {
      //   path: '',
      //   redirectTo: 'home',
      //   pathMatch: 'full'
      // },
      // 
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((module) => module.HomeModule),
        // canActivateChild: [AuthGuard]
      },
      {
        path: 'setting',
        loadChildren: () => import('./setting/setting.module').then((module) => module.SettingModule)
      },
      {
        path: 'client',
        loadChildren: () => import('./client/client.module').then((module) => module.ClientModule)
      },
      {
        path: 'client/client-details/:id',
        loadChildren: () => import('./client-details/client-details.module').then((module) => module.ClientDetailsModule)
      },
      // {
      //   path: 'analytics',
      //   loadChildren: () => import('./analytics/analytics.module').then((module) => module.AnalyticsModule)
      // },
      {
        path: 'techSupport',
        loadChildren: () => import('./tech-support/tech-support.module').then((module) => module.TechSupportModule)
      },
      {
        path: 'salesAnalytics',
        loadChildren: () => import('./sales-analytics/sales-analytics.module').then((module) => module.SalesAnalyticsModule)
      },
      {
        path: 'activations',
        loadChildren: () => import('./activation/activation.module').then((module) => module.ActivationModule)
      },
      {
        path: 'companyDetails',
        loadChildren: () => import('./company-details/company-details.module').then((module) => module.CompanyDetailsModule)
      },
      { 
        path: '**', 
        component: PageNotFoundComponent,
      },
    ]
  },
  {
    path: '',
    component: SigninComponent,
    children: [

      {
        path: 'signin',
        loadChildren: () => import('./signin/signin.module').then((module) => module.SigninModule),

      }
    ]
    // canActivate: [AdminGuard]
  },
  // { 
  //   path: '**', 
  //   component: PageNotFoundComponent,
  //   // loadChildren: () => import('./signin/signin.module').then((module) => module.SigninModule)

  // },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
