import { Injectable } from '@angular/core';

export interface NavigationItem {
    id: string;
    title: string;
    type: 'item' | 'collapse' | 'group';
    translate?: string;
    icon?: string;
    hidden?: boolean;
    url?: string;
    classes?: string;
    exactMatch?: boolean;
    external?: boolean;
    target?: boolean;
    breadcrumbs?: boolean;
    function?: any;
    badge?: {
        title?: string;
        type?: string;
    };
    children?: Navigation[];
}

export interface Navigation extends NavigationItem {
    children?: NavigationItem[];
}

const NavigationItems = [
    {
        id: 'admin',
        title: 'Admin',
        type: 'group',
        icon: 'feather icon-align-left',
        children: [
            {
                id: 'home',
                title: 'الصفحة الرثيسية',
                type: 'item',
                url: './home',
                classes: 'nav-item',
                icon: 'feather icon-sidebar'
            },
            {
                id: 'client',
                title: 'العملاء',
                type: 'item',
                url: './client',
                classes: 'nav-item',
                icon: 'feather icon-sidebar'
            },
            // {
            //     id: 'analytics',
            //     title: 'احصائيات',
            //     type: 'item',
            //     url: 'analytics',
            //     classes: 'nav-item',
            //     icon: 'feather icon-sidebar'
            // },
            {
                id: 'techSupport',
                title: 'خدمة العملاء',
                type: 'item',
                url: 'techSupport',
                classes: 'nav-item',
                icon: 'feather icon-sidebar'
            },
            {
                id: 'Setting',
                title: 'الاعدادات',
                type: 'item',
                url: 'setting',
                classes: 'nav-item',
                icon: 'feather icon-sidebar'
            },
            {
                id: 'Reports',
                title: 'التقارير',
                type: 'item',
                url: ' ',
                classes: 'nav-item',
                icon: 'feather icon-sidebar'
            },
            {
                id: 'Sales',
                title: 'المبيعات',
                type: 'item',
                url: 'salesAnalytics',
                classes: 'nav-item',
                icon: 'feather icon-sidebar'
            },
            {
                id: 'Sales',
                title: 'بيانات الشركة',
                type: 'item',
                url: 'companyDetails',
                classes: 'nav-item',
                icon: 'feather icon-sidebar'
            }
        ]
    }
];

@Injectable()
export class NavigationItem {
    public get() {
        return NavigationItems;
    }
}
