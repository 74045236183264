import { Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() type: string = 'default';
  @Input() list: { id: number, name: string, name_ar: string }[];


  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  @Output() selectionChange = new EventEmitter<any>();

  onChange(selectedValue: any) {
    // console.log('Selected value:', selectedValue);

    this.selectionChange.emit(selectedValue);
  }

  writeValue(obj: any): void {
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

}
